import rollbar from 'rollbar'
import myAppConfig from './myAppConfig'
import get from 'lodash/get'

const { environment, uuid, rollbarAccessToken } = myAppConfig

// TODO use transform to report isNetworkError and isRequestAborted as info
const isIgnored = (isUncaught, args, payload) => {
  const message = get(payload, 'body.trace.exception.message')

  // browser lost connection
  // https://developer.mozilla.org/en-US/docs/Web/API/DOMException#exception-NetworkError
  const isNetworkError = message === 'Network Error'
  // browser was reloaded in the middle of request
  // https://developer.mozilla.org/en-US/docs/Web/API/DOMException#exception-AbortError
  const isRequestAborted = message === 'Request aborted'

  return isNetworkError || isRequestAborted
}

const rollbarConfig = {
  accessToken: rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: ['cloudfront.net', 'babbel.com', 'babbel.cn'],
  enabled: environment !== 'development',
  checkIgnore: isIgnored,
  reportLevel: 'warning', // only report errors that are at least 'warning' & 'error', not debug, etc
  payload: {
    environment,
    person: { id: uuid },
    client: {
      javascript: {
        // Git SHA deployed code
        code_version: process.env.BUILD_COMMIT_HASH,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
}

const Rollbar = rollbar.init(rollbarConfig)
window.Rollbar = Rollbar

export default Rollbar
