import APIClient from '@lessonnine/api-client.js'
import config from '../lib/myAppConfig'

let apiClient
const appMode = process.env.APP_MODE

const getClient = () => {
  if (!apiClient) {
    const apiClientConfig = {
      baseUrl: config.apigatewayBaseUrl,
      awsSessionEndpoint:
        appMode === 'standalone' ? '/aws_session.json' : config.awsSessionEndpoint,
      awsSessionMethod: config.awsSessionMethod,
    }

    apiClient = new APIClient(apiClientConfig)
  }

  return apiClient
}

export default getClient
