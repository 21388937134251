import axios from 'axios'
import get from 'lodash/get'

import './lib/rollbar'
import myAppConfig from './lib/myAppConfig'
import awsApiClient from './services/awsApiClient'
import spinnerImg from './images/babbel-spinner.gif'

const { uuid, apigatewayBaseUrl, locale, tld, intensiveBaseUrl } = myAppConfig

const validateRedirectUrl = (redirectUrl) => {
  const urlComponents = new URL(redirectUrl)

  if (intensiveBaseUrl.includes(urlComponents.origin)) {
    if (urlComponents.searchParams.get('redirect')) {
      const redirectUrlComponents = new URL(urlComponents.searchParams.get('redirect'))
      return intensiveBaseUrl.includes(redirectUrlComponents.origin)
    }
    return true
  }
}

const handleError = (error, wrapper, spinner) => {
  const errorLevel = error.level === 'warning' ? 'warning' : 'error'
  window.Rollbar[errorLevel](error, { response: error.response })

  // display error message
  const tittleContainer = document.createElement('div')
  const messageContainer = document.createElement('div')
  tittleContainer.setAttribute(
    'style',
    'display: flex; justify-content: center; align-items: center; font-size: 1.4rem; font-family: sans-serif; color: #333;', // eslint-disable-line
  )
  messageContainer.setAttribute(
    'style',
    'display: flex; justify-content: center; align-items: center; font-size: 1rem; font-family: sans-serif; color: #333;', // eslint-disable-line
  )
  const header = document.createTextNode('An error occurred')
  const message = document.createTextNode('please try again in a few minutes')
  tittleContainer.appendChild(header)
  messageContainer.appendChild(message)
  const errorNode = document.createElement('div')
  errorNode.appendChild(tittleContainer)
  errorNode.appendChild(messageContainer)
  wrapper.replaceChild(errorNode, spinner)
}

const initializeElements = () => {
  const containerStyle = 'width: 100%; height: 100%; margin-top: 200px; display: flex; justify-content: center; align-items: center;' // eslint-disable-line
  const spinnerStyle = 'margin: auto; display: flex;'

  const wrapper = document.createElement('div')
  wrapper.setAttribute(
    'style',
    containerStyle,
  )
  const spinner = document.createElement('img')
  spinner.setAttribute(
    'style',
    spinnerStyle
  )
  spinner.src = spinnerImg
  spinner.role = 'presentation'
  wrapper.appendChild(spinner)
  document.querySelector('[data-main]').appendChild(wrapper)

  return { wrapper, spinner }
}

const getLoginRedirectUrl = () => (
  `https://accounts.babbel.${tld}/${locale}/accounts/sign_in?return_to=${encodeURI(window.location.href)}`
)


const requestIntensiveProviderData = async (apiClient) => {
  const response = await apiClient.get(`v3/${locale}/organizations/accounts/me/intensive_providers`)
  const usesBabbalPrivateLive = get(response, 'data.account.uses_babbel_private_live')

  if (typeof usesBabbalPrivateLive === 'undefined') {
    const error = new Error('Unexpected response from organizations app')
    error.response = response
    throw error
  }

  return usesBabbalPrivateLive
}

const requestAccount = async (apiClient) => {
  try {
    return await apiClient.get(`v1.2.0/${locale}/users/me`)
  } catch (_e) {
    return
  }
}

const getAuthenticationRequest = (meResponse) => {
  const me = meResponse.data.user

  return {
    starting_signin_url: window.location.href,
    my_app_config: {
      uuid,
      auth_token: me.authentication_token,
    },
  }
}

const requestLearncubeRedirectUrl = async (meResponse) => {
  const request = axios.create({
    baseURL: apigatewayBaseUrl,
    timeout: 30000,
  })
  const response = await request.post('/v1.0.0/live/session_url', getAuthenticationRequest(meResponse))

  if (response && response.data && response.data.redirect_url) {
    const redirectUrl = response.data.redirect_url

    if (validateRedirectUrl(redirectUrl)) return redirectUrl

    const error = new Error('Security error: redirect_url is not for an authorized domain')
    error.response = response
    throw error
  } else {
    // TODO: Verify if these errors are all due to authentication
    const error = new Error('Error with authentication')
    error.response = response
    error.level = 'warning'
    throw error
  }
}

const getRedirectUrl = async () => {
  const { wrapper, spinner } = initializeElements()

  try {
    const apiClient = awsApiClient()

    try {
      await apiClient.authenticate(locale)
    } catch (_e) {
      return getLoginRedirectUrl()
    }

    // Request all account data at once for optimization. This way, users who don't use babbel live for intensive don't
    // need to wait for an extra request.
    const responses = await Promise.all([requestIntensiveProviderData(apiClient), requestAccount(apiClient)])

    const usesBabbalPrivateLive = responses[0]
    if (usesBabbalPrivateLive) {
      return `https://my.babbel.${tld}/tutoring`
    }

    const meResponse = responses[1]
    if (!meResponse) return getLoginRedirectUrl()

    const redirectUrl = await requestLearncubeRedirectUrl(meResponse)
    return redirectUrl
  } catch (e) {
    // If Non-B2B users try to access, the BE will return 404 and they should be redirected to the tutoring page
    if (e?.response?.status === 404) {
      return `https://my.babbel.${tld}/tutoring`
    }
    handleError(e, wrapper, spinner)
  }
}

export default getRedirectUrl
