import { getMyEnv } from '@lessonnine/my.js'

/*
  data-config is a json string which would be set by my.app.
  This contains all the environment. variables which are needed for core and child apps
*/

const {
  uuid,
  environment,
  authToken,
  apigatewayBaseUrl,
  myBaseUrl,
  assetUrl,
  locale,
  awsSessionEndpoint,
  awsSessionMethod,
  rollbarAccessToken,
} = getMyEnv()

const envIsProduction = environment === 'production'
const envIsStaging = environment === 'staging'
const tld = envIsProduction ? 'com' : 'cn'
const intensiveBaseUrl = [`https://intensive.babbel.${tld}`, `https://my.babbel.${tld}`]

/*
  use commonjs export rather than es6 export in order to not need
    to export each variable individually
*/
const config = {
  uuid,
  environment,
  authToken,
  apigatewayBaseUrl,
  myBaseUrl,
  assetUrl,
  envIsProduction,
  envIsStaging,
  // as we currently do not support en_GB in B2B systems, we need to convert it to en
  locale: locale === 'en_GB' ? 'en' : locale,
  tld,
  awsSessionEndpoint,
  awsSessionMethod,
  rollbarAccessToken,
  intensiveBaseUrl,
}

export default config
